<template>
  <v-card class="cardDialog" :height="height">
    <v-container style="background-color: #ffffff">
      <v-row>
        <v-col align="center" justify="center">
          <v-img
            class="imageYellowPetWarning"
            src="@/assets/img/yellow_pet_warning.svg"
            alt="Mascota saludando"
          />
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="bodyAmarillo headerRoundedWhiteAmarilloWarning"
      >
        <v-col align="center" justify="center">
          <div style="margin: 25px" v-html="messageError" />
        </v-col>
      </v-row>
      <v-row align="center" class="bodyAmarillo">
        <v-col align="center" justify="center">
          <v-btn
            bottom
            depressed
            rounded
            class="btnTransparenteWarning"
            @click="next()"
          >
            {{ btnHomeTxt }}
          </v-btn>
        </v-col>
        <v-col align="center" justify="center">
          <v-btn bottom depressed rounded class="btnBlanco" @click="close()">
            {{ btnStay }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { homeRoute } from "@/helper/homeRoute";

export default {
  name: "WarningModal",
  data: () => ({
    messageError: null,
    btnHomeTxt: "",
    btnStay: "",
    height: "552px !important",
  }),
  mounted() {
    this.messageError =
      "Por favor volvé a intentar o contactate por WhatsApp\n" +
      "            con nuestro equipo de soporte.";
    let errorMessageState = this.$store.getters.getWarningMessage;
    this.btnHomeTxt = this.$store.getters.getBtnTxtHome;
    this.btnStay = this.$store.getters.getBtnTxtStay;
    if (errorMessageState !== "") {
      this.messageError = errorMessageState;
      if (this.messageError.includes("base")) {
        this.height = "552px !important";
      }
      if (this.messageError.includes("sale")) {
        this.height = "504px !important";
      }
      if (this.messageError.includes("ya sos cliente")) {
        this.height = "450px !important";
      }
    }
  },
  methods: {
    ...mapActions(["resetStore", "goWarningModal", "setWarningMessageModal"]),
    next() {
      this.goWarningModal(false);
      this.setWarningMessageModal("");
      this.resetStore();
      this.$router.replace({ name: homeRoute.HOME });
    },
    close() {
      this.goWarningModal(false);
      this.setWarningMessageModal("");
    },
  },
};
</script>

<style scoped>
</style>